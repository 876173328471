import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const TRACKING_PATHNAMES: string[] = ['/subscribe/success']

export const useMomentSciencePixel = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (TRACKING_PATHNAMES.includes(pathname)) {
      const script = document.createElement('script')
      script.src = 'https://trk.adspostx.com/track/js?goal_id=3187'
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }

    return undefined
  }, [pathname])
}
