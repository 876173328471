import React, { useCallback, useEffect } from 'react'
import { useAuthContext } from '@meprism/shared/src/context/AuthContext'
import { AuthenticationService } from '@meprism/shared/src/services/AuthenticationService'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { store } from '../../redux/store'
import { fetchCompanyFederation } from '../../redux/product/ProductSlice'
import { Logger } from '@meprism/app-utils'
import Loader from '@meprism/shared/src/components/atoms/Loader'
import { Box } from '@mui/material'

const FaderatedSignIn: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const authContext = useAuthContext()
  
  const signInWithFederation =  useCallback(async (setUserCallback: any, token: string) => {
    try {
      if (token) {
        const jsonString = atob(token)
        const { user_email: userEmail } = JSON.parse(jsonString)
  
        const response = await store.dispatch(
          fetchCompanyFederation({ user_email: userEmail }),
        )
  
        if(response.payload) {
          await AuthenticationService.signInWithFederation(
            '/dashboard',
            setUserCallback,
            response.payload
          )
        } else {
          throw Error(`Failure in signing in federation with token: ${token}`)
        }
      }
    } catch(error) {
      navigate('/login/signin')
    }
  }, [navigate])

  useEffect(() => {
    try {
      const token = searchParams.get('token')
      if(token) {
        signInWithFederation(authContext.setUser, token)
        return
      }

      throw Error(`Failure in signing in federation with token: ${token}`)
    } catch(error) {
      navigate('/login/signin')
      Logger.error(`Error attempting autosignin: ${error}`)
    }
  }, [navigate, searchParams, authContext, signInWithFederation])

  return (
    <Box sx={{ display: 'flex', width: '100%', backgroundColor: 'black', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Loader loading={true} />
    </Box>
  )
}

export default FaderatedSignIn
