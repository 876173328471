import { getUserAuthToken } from '../services/AuthenticationService'

export const buildAwsEndpoints = (API_FQDN: string) => [
  {
    name: 'User',
    endpoint: 'https://' + API_FQDN + '/api/user',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'UserUnauthenticated',
    endpoint: 'https://' + API_FQDN + '/api/user',
  },
  {
    name: 'PushNotification',
    endpoint: 'https://' + API_FQDN + '/api/push-notification',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'UILogging',
    endpoint: 'https://' + API_FQDN + '/api/ui-logging',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'Payment',
    endpoint: 'https://' + API_FQDN + '/api/payment',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'Profile',
    endpoint: 'https://' + API_FQDN + '/api/profile',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'AdsInterest',
    endpoint: 'https://' + API_FQDN + '/api/ads-interest',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'KVS',
    endpoint: 'https://' + API_FQDN + '/api/kvs',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'Export',
    endpoint: 'https://' + API_FQDN + '/api/export',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'Fraud',
    endpoint: 'https://' + API_FQDN + '/api/fraud',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'Alert',
    endpoint: 'https://' + API_FQDN + '/api/inbox',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'PeopleFinder',
    endpoint: 'https://' + API_FQDN + '/api/people-finder',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'Subscription',
    endpoint: 'https://' + API_FQDN + '/api/subscription',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'SubscriptionUnauthenticated',
    endpoint: 'https://' + API_FQDN + '/api/subscription',
  },
  {
    name: 'PPC',
    endpoint: 'https://' + API_FQDN + '/api/ppc',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'Corporate',
    endpoint: 'https://' + API_FQDN + '/internal/subscription',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'CorpOnboarding',
    endpoint: 'https://' + API_FQDN + '/api/corp-onboarding',
    custom_header: async () => {
      const idToken = await getUserAuthToken()
      return { Authorization: idToken }
    },
  },
  {
    name: 'PlaywrightService',
    endpoint: 'https://dataright.' + API_FQDN,
    //     custom_header: async () => {
    //       const idToken = await getUserAuthToken()
    //       return { Authorization: idToken }
    //     },
  },
]
