import React from 'react'
import { useLocation } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  Box,
  BoxProps,
  Container,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material'
import fast from '@meprism/shared/src/assets/icons/fast.svg'
import Link from '@mui/material/Link'
import AI from '@meprism/shared/src/assets/icons/AI.svg'
import Realtime from '@meprism/shared/src/assets/icons/Realtime.svg'
import logo from '@meprism/shared/src/assets/icons/logos/mePrismprivacynew.svg'
import background from '../../assets/images/IndividualCreateBackground.png'
import { ThemeProvider } from '@emotion/react'
import { MpReTheme, fonts, theme } from '../../theme/OnboardingTheme'
import icon4 from '@meprism/shared/src/assets/icons/lucide_scan-text.svg'
import icon5 from '@meprism/shared/src/assets/icons/no.svg'
import LoginOTP from './LoginOTP'

export type SignInTab = 'create' | 'sign in'
function Item (props: BoxProps) {
  const { ...other } = props
  return <Box {...other} />
}
export const AuthCopy = ({}: { mode: SignInTab }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ px: 10, pt: 10 }}>
          <img src={logo} alt='Logo' style={{ width: 200, height: 50 }} />{' '}
        </Box>

        <Stack
          sx={{
            px: 10,
            pt: 10,
            pb: 20,
            '& .MuiTypography-root': {
              color: '#FFFFFF',
            },
            justifyContent: 'flex-end',
          }}>
          <Typography
            variant={'h1'}
            sx={{
              my: 2,
              fontWeight: 'bold',
              maxWidth: !isMobile ? '400px' : 'unset',
            }}>
            Why choose mePrism?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              columnGap: 1,
              rowGap: 1,
              alignItems: 'center',
              gridTemplateColumns: 'auto, 5fr',
            }}>
            <Item>
              <img
                src={fast}
                alt='Logo'
                style={{ marginRight: 8, width: 42, height: 42 }}
              />
            </Item>
            <Item>
              {' '}
              <Typography variant='body2'>
                Fast, continuous removal{' '}
                <span style={{ color: '#EFEDFDB2' }}>
                  of personal data (PII) from the web
                </span>
              </Typography>{' '}
            </Item>
          </Box>
          <Box
            sx={{
              display: 'flex',
              columnGap: 1,
              rowGap: 1,
              alignItems: 'center',
              gridTemplateColumns: 'auto, 1fr',
            }}>
            <Item>
              <img
                src={Realtime}
                alt='Logo'
                style={{ marginRight: 8, width: 42, height: 42 }}
              />
            </Item>
            <Item sx={{ gridColumn: 'span 5' }}>
              <Typography variant='body2'>
                Real-time monitoring{' '}
                <span style={{ color: '#EFEDFDB2' }}>& reporting </span>
              </Typography>{' '}
            </Item>
          </Box>
          <Box
            sx={{
              display: 'flex',
              columnGap: 1,
              rowGap: 1,
              gridTemplateColumns: 'auto, 1fr',
              alignItems: 'center',
            }}>
            <Item>
              <img
                src={AI}
                alt='Logo'
                style={{ marginRight: 8, width: 42, height: 42 }}
              />
            </Item>
            <Item>
              <Typography variant='body2'>
                <span style={{ color: '#EFEDFDB2' }}> Powered by </span>
                automation and AI +{' '}
                <span style={{ color: '#EFEDFDB2' }}>
                  legal intervention
                </span>{' '}
              </Typography>
            </Item>
          </Box>
          <Box
            sx={{
              alignItems: 'left',
              marginTop: 4,
              p: 10,
              backgroundColor: 'rgba(255, 255, 255, 0.15)',
              border:
                '2px solid var(--text-colors-stroke, rgba(255, 255, 255, 0.15))',
              borderRadius: '20px',
              backdropFilter: 'blur(20.5px)',
            }}>
            <Typography variant='h4'>
              Information <span style={{ color: '#C67EFF' }}>Security</span>
            </Typography>
            <Grid
              container
              spacing={4}
              sx={{
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginTop: 4,
              }}>
              {/* Item 1 */}
              <Grid item xs={12} md={6}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <img
                    src={icon4}
                    alt='Icon2'
                    style={{
                      verticalAlign: 'middle',
                      marginRight: 8,
                      width: 24,
                      height: 24,
                    }}
                  />
                  <Typography variant='body3'>
                    mePrism is SOC 2 compliant with industry-grade cybersecurity
                    standards for handling sensitive data
                  </Typography>
                </Box>
              </Grid>

              {/* Item 2 */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginTop: isMobile ? 4 : 0,
                  }}>
                  <img
                    src={icon5}
                    alt='Icon2'
                    style={{
                      verticalAlign: 'middle',
                      marginRight: 8,
                      width: 24,
                      height: 24,
                      marginLeft: 5,
                    }}
                  />
                  <Typography variant='body3'>
                    We do not sell your information
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </ThemeProvider>
    </>
  )
}

export const VerifyOtpWrapper = () => {
  const location = useLocation()
  const textStyle = {
    ...fonts.Inter.Regular,
    fontSize: '14px',
  }
  const mode: SignInTab = location.pathname.includes('create')
    ? 'create'
    : 'sign in'
  const isWide = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} sx={{ background: '#1A1B20' }}>
        <Grid container>
          <Grid item xs={12} md={5}>
            {!isWide ? (
              <Tabs
                sx={{ px: 10, pt: 10, '.MuiTab-root': { alignItems: 'start' } }}
                value={1}
                indicatorColor='secondary'
                textColor='primary'
                centered={false}>
                <Tab
                  style={{ minWidth: '20%' }}
                  label='1.'
                  sx={{
                    flexGrow: 1,

                    textTransform: 'none',
                    fontFamily: fonts.Inter.SemiBold,
                  }}
                />
                <Tab
                  label='2.Verify'
                  style={{ minWidth: '20%' }}
                  sx={{
                    width: '40%',
                    flexGrow: 0,
                    color: 'inherit',
                    margin: '0 2px',
                    '&.Mui-selected': {
                      color: MpReTheme.colors.tertiary.pink.default,
                    },
                    textTransform: 'none',
                    fontFamily: fonts.Inter.SemiBold,
                  }}
                />
                <Tab
                  label='3.'
                  style={{ minWidth: '20%' }}
                  sx={{
                    flexGrow: 0,
                    margin: '0 2px',
                    textTransform: 'none',
                    fontFamily: fonts.Inter.SemiBold,
                  }}
                />
                <Tab
                  label='4.'
                  style={{ minWidth: '20%' }}
                  sx={{
                    flexGrow: 0,
                    margin: '0 2px',
                    textTransform: 'none',
                    fontFamily: fonts.Inter.SemiBold,
                  }}
                />
              </Tabs>
            ) : location.state.actionType == 'SIGNIN_SOFTWARE_TOKEN_MFA' ? (
              <Tabs
                sx={{ px: 10, pt: 10, '.MuiTab-root': { alignItems: 'start' } }}
                value={1}
                indicatorColor='secondary'
                textColor='primary'>
                <Tab
                  label='1.Login'
                  sx={{
                    textTransform: 'none',
                    flexGrow: 1,
                    margin: '0 2px',
                    fontFamily: fonts.Inter.SemiBold,
                  }}
                />
                <Tab
                  label='2.Verify'
                  sx={{
                    textTransform: 'none',
                    flexGrow: 1,
                    margin: '0 2px',
                    fontFamily: fonts.Inter.SemiBold,
                    color: 'inherit',
                    '&.Mui-selected': {
                      color: MpReTheme.colors.tertiary.pink.default,
                    },
                  }}
                />
              </Tabs>
            ) : (
              <Tabs
                sx={{ px: 10, pt: 10, '.MuiTab-root': { alignItems: 'start' } }}
                value={1}
                indicatorColor='secondary'
                textColor='primary'>
                <Tab
                  label='1.Sign Up'
                  sx={{
                    textTransform: 'none',
                    flexGrow: 1,
                    margin: '0 2px',
                    fontFamily: fonts.Inter.SemiBold,
                  }}
                />
                <Tab
                  label='2.Verify'
                  sx={{
                    textTransform: 'none',
                    flexGrow: 1,
                    margin: '0 2px',
                    fontFamily: fonts.Inter.SemiBold,
                    color: 'inherit',
                    '&.Mui-selected': {
                      color: MpReTheme.colors.tertiary.pink.default,
                    },
                  }}
                />
                <Tab
                  label='3.Set Profile'
                  sx={{
                    textTransform: 'none',
                    flexGrow: 1,
                    margin: '0 2px',
                    fontFamily: fonts.Inter.SemiBold,
                  }}
                />
                <Tab
                  label='4.Authorization'
                  sx={{
                    textTransform: 'none',
                    flexGrow: 1,
                    margin: '0 2px',
                    fontFamily: fonts.Inter.SemiBold,
                  }}
                />
              </Tabs>
            )}
            <Box sx={{ px: 5 }}>
              <LoginOTP />
              <Stack
                direction={'row'}
                sx={{
                  alignItems: 'center',
                  mx: 2,
                  mt: isWide ? 50 : 20,
                  mb: 10,
                }}
                spacing={2}
                justifyContent={'space-between'}>
                <Link
                  href={'https://meprism.com/terms-conditions'}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Terms & Conditions
                </Link>
                <Link
                  href={'https://meprism.com/privacy-policy'}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Privacy Policy
                </Link>
                <Link
                  href={
                    'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17'
                  }
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Contact Us
                </Link>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={0}
            md={7}
            sx={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              alignItems: 'flex-end',
            }}>
            <Box sx={{ position: 'relative', height: '100%' }}>
              <AuthCopy mode={mode} />
              <Typography
                variant='body4'
                sx={{ mx: 10 }}
                style={{ position: 'absolute', bottom: '40px' }}>
                Are you a Business?
                <Link
                  href={
                    'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17'
                  }
                  style={{
                    textDecoration: 'underline',
                    fontSize: '16px',
                    marginLeft: '5px',
                  }}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.tertiary.pink.default,
                  }}>
                  Contact us
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}
