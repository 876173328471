import React from 'react'
import { CircularProgress, Grid } from '@mui/material'

type LoaderPropTypes = {
  loading: boolean
  children?: React.ReactNode
}

const Loader = ({ loading, children}: LoaderPropTypes) => {
  return loading ? (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    <>{children}</>
  )
}

export default Loader
