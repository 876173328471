import React, { ReactNode, useState } from 'react'
import { MpButton, MpTypography } from '../shared'
import { Button } from '../shared/MaterialExports'
import {
  Controller,
  FieldArray,
  FieldArrayPath,
  UseFieldArrayReturn,
} from 'react-hook-form'
import {
  Box,
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import MpSvgIcon from '../shared/atom/MpSvgIcon'
import MpTheme from '@meprism/shared/src/config/MpTheme'
import { DatePicker } from '@mui/x-date-pickers'
import * as SigninTheme from '../../theme/OnboardingTheme'
import { fonts as fontsnew, MpReTheme } from '../../theme/OnboardingTheme'
// import * as themenew from '../../theme/theme'
import { Logger } from '@meprism/app-utils'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import {
  ProfileFormHookReturn,
  ProfileSchemaType,
  useProfileForm,
} from '../../utils/hooks/useProfileForm'
import { TextFieldProps } from '@mui/material/TextField'

enum OptionalFields {
  MIDDLE_NAME = 'MIDDLE_NAME',
  STREET = 'STREET',
  LINKEDIN = 'LINKEDIN',
}

enum OptionalAlertText {
  MIDDLE_NAME = 'Please note that if you have a middle name, not providing it will result in reduced and inaccurate exposure scan results',
  STREET = 'Not providing your street address will prevent us from removing you from some data brokers',
  LINKEDIN = 'Not providing your LinkedIn Profile URL will prevent us from removing you from some data brokers',
}

enum OptionalAlertButtonText {
  MIDDLE_NAME = 'Proceed without a middle name',
  STREET = 'Proceed without a street address',
  LINKEDIN = 'Proceed without a LinkedIn Profile URL',
}

// Address is not a single string schema, and we have to render it differently
// anyway because of its multiple inline inputs, no room for an inline remove, etc
type SantizedFieldArrayPath = Exclude<
  FieldArrayPath<ProfileSchemaType>,
  'address'
>

interface RemovableMappedInputProps<
  TFieldArrayName extends SantizedFieldArrayPath,
> {
  array: UseFieldArrayReturn<ProfileSchemaType, TFieldArrayName, 'id'>
  formKey: TFieldArrayName
  minimumLength?: number
  maximumLength?: number
  errors: ProfileFormHookReturn['formState']['errors']
  register: ProfileFormHookReturn['register']
  label: string
  labelIcon?: ReactNode
  defaultValue: FieldArray<ProfileSchemaType, TFieldArrayName>
  header?: string
  inputProps?: TextFieldProps['InputProps']
  control?: ProfileFormHookReturn['control']
  required?: boolean
  disabled?: boolean
}

const styles = {
  inputContainer: {
    // maxWidth: MpTheme.layouts.widths.sm,
    width: '100%',
  },
  optional: {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'flex-start',
  },
  dialogTitle: {
    fontSize: '10px',
    fontFamily: MpTheme.fonts.plusJakartaSansSemibold,
  },
  header: {
    mb: 5,
  },
} as const

const RemovableMappedInput = <TFieldArrayName extends SantizedFieldArrayPath>({
  array,
  formKey,
  // minimumLength = 0,
  // maximumLength = 10,
  errors,
  register,
  label,
  // labelIcon,
  // defaultValue,
  header = label,
  inputProps,
  control,
  required,
  disabled = false,
}: RemovableMappedInputProps<TFieldArrayName>) => {
  const err = errors?.[formKey]

  return (
    <Box sx={styles.inputContainer}>
      {array.fields.map((field, index) => {
        return (
          <Box
            key={field.id}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            {control ? (
              <Controller
                render={({ field: controlledField }) => (
                  <TextField
                    error={!!(Array.isArray(err) ? err?.[index]?.value : false)}
                    helperText={
                      Array.isArray(err)
                        ? err?.[index]?.value?.message || ' '
                        : ' '
                    }
                    {...(index === 0 && { label: '', placeholder: header })}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ ...inputProps }}
                    fullWidth
                    {...controlledField}
                    label={'foo'}
                  />
                )}
                name={`${formKey}.${index}.value` as const}
                control={control}
                disabled={disabled}
              />
            ) : (
              <TextField
                error={!!(Array.isArray(err) ? err?.[index]?.value : false)}
                helperText={
                  Array.isArray(err) ? err?.[index]?.value?.message || ' ' : ' '
                }
                InputLabelProps={{ shrink: true }}
                required={required}
                {...(index === 0 && { label: '', placeholder: header })}
                InputProps={{ ...inputProps }}
                {...register(`${formKey}.${index}.value` as const)}
                fullWidth
                disabled={disabled}
              />
            )}
            {/* {index >= minimumLength && (
              <Box>
                <MpButton variant={'text'} onClick={() => array.remove(index)}>
                  Remove
                </MpButton>
                <FormHelperText> </FormHelperText>
              </Box>
            )} */}
          </Box>
        )
      })}
      {/* {array.fields.length < maximumLength && (
        <Box sx={{ width: '100%', display: 'flex' }}>
          <Button
            variant={'text'}
            onClick={() => array.append(defaultValue)}
            startIcon={labelIcon}
            sx={{
              alignSelf: 'flex-start',
              '&.MuiButton-text': { fontFamily: fonts.Jakarta.Regular },
            }}>
            Add {label}
          </Button>
        </Box>
      )} */}
    </Box>
  )
}

export const PrivacyOnboardingView = () => {
  const navigate = useNavigate()
  const [middleNameOptional, setMiddleNameOptional] = useState(false)
  const [streetOptional, setStreetOptional] = useState(false)
  const [linkedinOptional, setLinkedinOptional] = useState(false)
  const [alertTitle, setAlertTitle] = useState('')
  const [alertButtonText, setAlertButtonText] = useState('')
  const [openDialog, setDialog] = useState(false)

  const {
    firstNameArray,
    lastNameArray,
    middleNameArray,
    addressArray,
    register,
    watch,
    errors,
    control,
    setValue,
    handleSubmit,
    submitHandler,
    trigger,
  } = useProfileForm({
    middleNameOptional,
    linkedinOptional,
    streetOptional,
    agreeToTermsOptional: true,
    onSubmitSuccess: async () => {
      try {
        // this one activates profile with OneRep
        // this API is prone to change!
        // await dispatch(putProfile()).unwrap()
        // TODO: Activate a scan somehow
        // await dispatch(setupProfile()).unwrap()
        navigate('/authorization')
      } catch (error) {
        Logger.error(`Error setting up OneRep profile: ${error}`)
        toast.error('Your profile could not be processed')
      }
    },
    onSubmitError: (error) => {
      Logger.error(`Error setting up mePrism profile: ${error}`)
      toast.error('Your profile could not be processed')
    },
  })
  const mpAuthLetterSignature = watch('mp_auth_letter_signature')
  if (
    firstNameArray?.fields.length > 0 &&
    firstNameArray?.fields?.[0]?.value !== '' &&
    mpAuthLetterSignature !== ''
  ) {
    navigate('/dashboard')
  }
  // const setName = (name: string) => setValue('mp_auth_letter_signature', name)

  const textStyle = {
    ...fontsnew.Inter.Regular,
    fontSize: '14px',
  }
  // const onboardingTheme = SigninTheme.theme
  // const defaultTheme = themenew.theme
  const isMobile = useMediaQuery(SigninTheme.theme.breakpoints.down('sm'))

  const handleCheckboxChange = (type: OptionalFields, value: boolean) => {
    if (value) {
      switch (type) {
        case OptionalFields.MIDDLE_NAME:
          setAlertTitle(OptionalAlertText.MIDDLE_NAME)
          setAlertButtonText(OptionalAlertButtonText.MIDDLE_NAME)
          setDialog(true)
          setValue('middleName', [{ value: '' }])
          break
        case OptionalFields.STREET:
          setAlertTitle(OptionalAlertText.STREET)
          setAlertButtonText(OptionalAlertButtonText.STREET)
          setDialog(true)
          break
        case OptionalFields.LINKEDIN:
          setAlertTitle(OptionalAlertText.LINKEDIN)
          setAlertButtonText(OptionalAlertButtonText.LINKEDIN)
          setDialog(true)
          setValue('linkedIn', '')
          break
        default:
          break
      }
    }
  }

  const handleCloseDialog = () => {
    setDialog(false) // Close the dialog
    if (alertTitle === OptionalAlertText.MIDDLE_NAME) {
      trigger('middleName')
    }
    if (alertTitle === OptionalAlertText.STREET) {
      trigger('address')
    }
    if (alertTitle === OptionalAlertText.LINKEDIN) {
      trigger('linkedIn')
    }
  }

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          <Typography variant={'h4'} style={{ color: '#4c72f4', fontSize: 18 }}>
            {alertTitle}
          </Typography>
        </DialogTitle>
        <Button sx={{ alignSelf: 'center', mb: 4 }} onClick={handleCloseDialog}>
          {alertButtonText}
        </Button>
      </Dialog>
      <Box
        sx={{
          textAlign: 'left',
          alignItems: 'center',
          px: 3,
          mt: 16,
          maxWidth: MpTheme.layouts.widths.sm,
          mx: 'auto',
        }}>
        <MpTypography variant={'h2'} color={'#ffffff'}>
          Create Your mePrism Profile
        </MpTypography>
      </Box>
      <Stack
        component={'form'}
        onSubmit={handleSubmit(submitHandler)}
        spacing={2}
        sx={{
          maxWidth: MpTheme.layouts.widths.sm,
          mx: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: 3,
          mt: 5,
          textAlign: 'center',
          '& .MuiFormLabel-asterisk': {
            color: 'red',
          },
        }}>
        <Stack
          spacing={1}
          sx={{
            maxWidth: 'sm',
            alignItems: 'center',
            width: '100%',
            px: 'auto',
          }}>
          <RemovableMappedInput
            array={firstNameArray}
            formKey={'firstName'}
            errors={errors}
            register={register}
            header={'First Name *'}
            label={'a Nickname'}
            minimumLength={1}
            required
            labelIcon={
              <MpSvgIcon
                icon={'profile'}
                color={MpTheme.colors.primary.default}
              />
            }
            defaultValue={{ value: '' }}
          />
          <RemovableMappedInput
            array={middleNameArray}
            formKey={'middleName'}
            errors={errors}
            register={register}
            label={'Middle Name or Initial'}
            labelIcon={
              <MpSvgIcon
                icon={'profile'}
                color={MpTheme.colors.primary.default}
              />
            }
            defaultValue={{ value: '' }}
            disabled={middleNameOptional}
            inputProps={{
              sx: {
                '&.Mui-disabled': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: MpTheme.colors.white,
                },
                marginBottom: errors?.middleName ? 0 : -6,
              },
            }}
          />
          <Box sx={styles.optional}>
            <Checkbox
              checked={middleNameOptional}
              onChange={() => {
                setMiddleNameOptional(!middleNameOptional)
                handleCheckboxChange(
                  OptionalFields.MIDDLE_NAME,
                  !middleNameOptional,
                )
              }}
            />
            <MpTypography variant={'body3'} sx={{ mt: 3, textAlign: 'left' }}>
              I don’t have a middle name
            </MpTypography>
          </Box>
          <RemovableMappedInput
            array={lastNameArray}
            formKey={'lastName'}
            errors={errors}
            register={register}
            header={'Last Name *'}
            label={'a Last Name'}
            minimumLength={1}
            required
            labelIcon={
              <MpSvgIcon
                icon={'profile'}
                color={MpTheme.colors.primary.default}
              />
            }
            defaultValue={{ value: '' }}
            inputProps={{
              sx: {
                marginTop: 2,
              },
            }}
          />
          <Box style={styles.inputContainer}>
            <Stack divider={<Divider sx={{ mb: 4 }} />}>
              {addressArray.fields.map((field, index) => (
                <Box key={field.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                    }}>
                    <TextField
                      sx={{ width: '100%' }}
                      placeholder={'Street'}
                      required
                      disabled={streetOptional}
                      error={!!errors?.address?.[index]?.streetAddress}
                      helperText={
                        errors?.address?.[index]?.streetAddress?.message || ' '
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register(`address.${index}.streetAddress` as const)}
                      InputProps={{
                        sx: {
                          '&.Mui-disabled': {
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                          },
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: MpTheme.colors.white,
                          },
                          marginBottom: errors?.address?.[index]?.streetAddress
                            ?.message
                            ? 0
                            : -6,
                        },
                      }}
                    />
                  </Box>
                  <Box marginBottom={2} sx={styles.optional}>
                    <Checkbox
                      checked={streetOptional}
                      onChange={() => {
                        if (!streetOptional) {
                          setValue(`address.${index}.streetAddress`, '')
                        }
                        setStreetOptional(!streetOptional)
                        handleCheckboxChange(
                          OptionalFields.STREET,
                          !streetOptional,
                        )
                      }}
                    />
                    <MpTypography
                      variant={'body3'}
                      sx={{ mt: 3, textAlign: 'left' }}>
                      I don’t want to provide my street address
                    </MpTypography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}>
                    <TextField
                      sx={{ width: '60%' }}
                      placeholder={'City *'}
                      required
                      error={!!errors?.address?.[index]?.city}
                      helperText={
                        errors?.address?.[index]?.city?.message || ' '
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register(`address.${index}.city` as const)}
                    />
                    <TextField
                      placeholder="State *"
                      sx={{ width: '30%' }}
                      required
                      error={!!errors?.address?.[index]?.state}
                      helperText={
                        errors?.address?.[index]?.state?.message || ' '
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register(`address.${index}.state` as const, {
                        setValueAs: (v) => (v ? v.toUpperCase() : ''), // Handle null or undefined values
                      })}
                    />
                  </Box>
                  {/*<TextField*/}
                  {/*  label={'Street Address'}*/}
                  {/*  fullWidth*/}
                  {/*  error={!!errors?.address?.[index]?.streetAddress}*/}
                  {/*  helperText={!!errors?.address?.[index]?.message || ' '}*/}
                  {/*  InputLabelProps={{ shrink: true }}*/}
                  {/*  {...register(`address.${index}.streetAddress` as const)}*/}
                  {/*/>*/}
                </Box>
              ))}
            </Stack>
          </Box>
          <Box sx={{ alignItems: 'left', width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
              }}>
              <TextField
                sx={{ width: '100%' }}
                placeholder={'LinkedIn URL'}
                required
                disabled={linkedinOptional}
                error={!!errors?.linkedIn}
                helperText={errors?.linkedIn?.message || ' '}
                InputLabelProps={{ shrink: true }}
                {...register('linkedIn', {
                  onChange: async () => {
                    await trigger('linkedIn')
                  },
                  onBlur: async () => {
                    await trigger('linkedIn')
                  },
                })}
                InputProps={{
                  sx: {
                    '&.Mui-disabled': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    },
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: MpTheme.colors.white,
                    },
                    marginBottom: errors?.linkedIn?.message ? 0 : -6,
                  },
                }}
              />
            </Box>
            <Box marginBottom={2} sx={styles.optional}>
              <Checkbox
                checked={linkedinOptional}
                onChange={() => {
                  setLinkedinOptional(!linkedinOptional)
                  handleCheckboxChange(
                    OptionalFields.LINKEDIN,
                    !linkedinOptional,
                  )
                }}
              />
              <MpTypography variant={'body3'} sx={{ mt: 3, textAlign: 'left' }}>
                I don’t want to provide my LinkedIn Profile URL
              </MpTypography>
            </Box>
          </Box>
          <Box sx={{ alignItems: 'left', width: '100%' }}>
            <Typography style={{ textAlign: 'left', color: '#EFEDFDB2' }}>
              Birthdate: MM/DD/YYYY *
            </Typography>
            <Controller
              name={'birthdate'}
              control={control}
              render={({ field }) => (
                <DatePicker
                  value={field.value || null}
                  onChange={(newValue) => {
                    field.onChange(newValue)
                    trigger('birthdate')
                  }}
                  OpenPickerButtonProps={{ style: { color: '#6F8EF5' } }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        fullWidth
                        {...params}
                        error={!!errors?.birthdate}
                        helperText={errors?.birthdate?.message ?? ' '}
                        onBlur={async () => {
                          await trigger('birthdate')
                          field.onBlur()
                        }}
                      />
                    </>
                  )}
                />
              )}
            />
          </Box>
        </Stack>
        <Divider flexItem />
        <Stack
          spacing={5}
          sx={{
            maxWidth: 'sm',
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
            px: 'auto',
          }}>
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'flex-start',
            }}>
            <FormControl
              required
              error={!!errors.agreeToTerms} // Display error state if there’s an error
              component="fieldset"
              variant="standard"
              sx={{ width: '100%' }} // Ensures it aligns with other form fields
            >
              <FormControlLabel
                control={
                  <Controller
                    name="agreeToTerms"
                    control={control}
                    rules={{
                      validate: (value) =>
                        value || 'You must agree to the terms and conditions',
                    }}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue('agreeToTerms', e.target.checked)
                          trigger('agreeToTerms')
                        }}
                      />
                    )}
                  />
                }
                label={
                  <MpTypography
                    variant="caption"
                    sx={{ mt: 3, textAlign: 'left' }}>
                    I have read and agree to the{' '}
                    <Link
                      style={{ textDecoration: 'underline', fontSize: '16px' }}
                      underline="hover"
                      href="https://meprism.com/privacy-policy"
                      target="_blank" // Optional: Opens in new tab
                      rel="noopener noreferrer">
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link
                      style={{ textDecoration: 'underline', fontSize: '16px' }}
                      underline="hover"
                      href="https://meprism.com/terms"
                      target="_blank"
                      rel="noopener noreferrer">
                      Terms of Use
                    </Link>
                    .
                  </MpTypography>
                }
              />

              {errors.agreeToTerms && (
                <FormHelperText>
                  {'You must agree to the terms and conditions'}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          {/* {name === '' ? (
            <MpButton
              variant={'contained'}
              size={'large'}
              color={'darkPurple'}
              disabled={!privacyChecked}>
              Continue
            </MpButton>
          )  */}
          {/* : ( */}
          <MpButton
            type={'submit'}
            variant={'contained'}
            size={'large'}
            color={'darkPurple'}
            sx={{ width: '100%' }}
            onClick={handleSubmit(submitHandler)}>
            Continue
          </MpButton>
          {/* )} */}
        </Stack>
        {!isMobile && (
          <Box
            sx={{
              maxWidth: MpTheme.layouts.widths.sm,
              width: '100%',
              // position: 'absolute',
              // bottom: !isMobile ? '30px' : '80px',
            }}>
            <Stack
              direction={'row'}
              sx={{ alignItems: 'center', mt: 6 }}
              spacing={!isMobile ? 15 : 10}
              justifyContent={'space-between'}>
              <Link
                href={'https://meprism.com/terms-conditions'}
                sx={{
                  ...textStyle,
                  color: MpReTheme.colors.secondary.default,
                }}>
                Terms & Conditions
              </Link>
              <Link
                href={'https://meprism.com/privacy-policy'}
                sx={{
                  ...textStyle,
                  color: MpReTheme.colors.secondary.default,
                }}>
                Privacy Policy
              </Link>
              <Link
                href={
                  'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17'
                }
                sx={{
                  ...textStyle,
                  color: MpReTheme.colors.secondary.default,
                }}>
                Contact Us
              </Link>
            </Stack>
          </Box>
        )}
      </Stack>
    </>
  )
}